import { createContext } from 'react';

export type AppModalsContextType = {
    isShowModal: boolean,
    setApiErrorModal: (error: unknown) => void,
    hideAllModals: () => void
}

export const AppModalsContext = createContext<AppModalsContextType>(null!);



