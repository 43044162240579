export const initialState = {
  id: 0,
  uniqueName: '',
  name: '',
  oid: '',
  country: null,
  region: null,
  personCode: null,
  organizationCode: null,
  organizationName: null,
  teamName: null,
  teamCode: null,
  isGov: null,
  isActualTfp: null,
  isActualNfp: null,
  isActualMentor: null,
  permissions: [],
  roles: [],
  organizations: [],
};