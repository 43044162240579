// ----------------------------------------------------------------------

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const FONT_PRIMARY =
[
  'Inter',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');


const font_weight_medium = 500;
const font_weight_regular = 200;
const font_weight_bold = 600;

const  typographyPrint =  {
          
  fontWeightRegular: font_weight_regular,
  fontWeightMedium: font_weight_medium,
  fontWeightBold: font_weight_bold,
  //fontFamily: 'SourceSansPro-SemiBold, sans-serif', 
  fontFamily: FONT_PRIMARY,

  h3: {
    fontWeight: font_weight_bold,
    lineHeight: 1.5,
    letterSpacing: '.03rem',
    fontSize: '1.7rem',
  },
  
  h4: {
    fontWeight: font_weight_bold,
    lineHeight: 1.25,
    letterSpacing: '.03rem',
    fontSize: '1.2rem',
  },
  
  h5: {
    fontWeight: font_weight_bold,
    lineHeight: 1.25,
    letterSpacing: '.03rem',
    fontSize: '1.0rem',
  },
  
  h6: {
    fontWeight: font_weight_bold,
    lineHeight: 1.5,
    fontSize: '1.0rem',
  },
  
  subtitle1: {
    fontWeight: font_weight_medium,
    lineHeight: 1.0,
    fontSize: '1.0rem'
  },
  
  subtitle2: {
    fontWeight: font_weight_medium,
    lineHeight: 1.0,
    fontSize: '0.9rem'
  },
  
  body1: {
    fontWeight: font_weight_regular,
    lineHeight: 1.0,
    fontSize: '0.9rem'
  },
  
  body2: {
    fontWeight: font_weight_regular,
    lineHeight: 1.0,
    fontSize: '0.8rem'
  }
};

export default typographyPrint;
