import React, { useState } from 'react';
import { AppModalsContext, AppModalsContextType } from '../AppModalsContext';
import { ApiDetailedError, ApiErrorModal, checkErrorIsApiError } from 'api/interfaces/ApiErrors';


export const AppModalsContextProvider = ({ children }: { children: React.ReactNode }) => {

  const [showModal, setShowModal] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiDetailedError | null>(null);

  const hideAllModals = () => {
    setShowModal(false);
    setApiError(null);
  };

  const setApiErrorModal = (error: unknown) => {
    setApiError(checkErrorIsApiError(error));
    setShowModal(Boolean(error));
  };


  const contextValue: AppModalsContextType = {
    isShowModal: showModal,
    hideAllModals,
    setApiErrorModal
  };

  return (
    <AppModalsContext.Provider value={contextValue}>
      <>
        {showModal ? <>

          <ApiErrorModal
            error={apiError}
            onClose={() => setShowModal(false)}
          />

        </> : null}


        {children}
      </>
    </AppModalsContext.Provider>
  );
};
