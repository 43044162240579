import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import { useAuthContext } from '../contexts/AuthContext';

type QueryFn<T> = (init?: RequestInit) => Promise<T>;

export const useAuthQueryNew = <T>(
  queryName: QueryKey,
  queryFn: QueryFn<T>,
  options?: Omit<UseQueryOptions<T, unknown, T, QueryKey>, 'queryKey' | 'queryFn'>
) => {
  const { getToken } = useAuthContext();

  const queryFnWithToken = async (init?: RequestInit) => {
    const token = await getToken();
    return queryFn({
      ...init,
      headers: {
        ...init?.headers,
        'Authorization': `Bearer ${token}`
      }
    });
  };

  return useQuery(queryName, queryFnWithToken, options);
};

export const useRequestWithAuth = () => {
  const { getToken } = useAuthContext();

  const wrapRequestWithAuth = async <T>(request: QueryFn<T>) => {
    const token = await getToken();
    return request({
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  };
  return {
    wrapRequestWithAuth
  };
};
