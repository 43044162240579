import { MutationOptions, useMutation } from 'react-query';
import { useAuthContext } from '../contexts/AuthContext';


export default function useAuthMutation<
    TData = unknown,
    TError = unknown,
    TVariables = void,
    TContext = unknown,
>(
  mutationFn: (variables: TVariables, init?: RequestInit) => Promise<TData>,
  options?: MutationOptions<TData, TError, TVariables, TContext>
) {
  const { getToken } = useAuthContext();

  const mutationFunc = async (variables: TVariables) => {
    const token = await getToken();
    return mutationFn(
      variables,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept-Language': 'en-US',
        }
      });
  };

  return useMutation(mutationFunc, options);
}
