import * as React from 'react';

/**
 * Hook for running effect on every render, except initial one
 *
 * @param {Function} effect - Effect to be run
 * @param {Array} deps - Dependencies to be watched
 */
export const useDidUpdate = (effect: () => void, deps: unknown[]): void => {
  const didMount = React.useRef(false);

  React.useEffect(() => {
    if (didMount.current) {
      effect();
    } else {
      didMount.current = true;
    }
  }, deps);
};
