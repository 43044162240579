import { useCallback } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { ROLES_LIST } from 'components/Auth/authConfig';

export const useCheckUserRoles = () => {
  const authContext = useAuthContext();

  return useCallback((rolesToCheck: ROLES_LIST[]): boolean => {
    if (!authContext || !authContext.user || !authContext.contextUser) {
      return false;
    }

    const userRoles: ROLES_LIST[] = authContext?.contextUser?.roles ?? [];

    if (!userRoles || !Array.isArray(userRoles) || !userRoles.length || userRoles.length === 0) {
      return false;
    }

    if (userRoles?.some(role => role === ROLES_LIST.ROLE_SUPERUSER)) {
      return true;
    }

    if (!rolesToCheck.length) {
      return true;
    }

    for (const role of rolesToCheck) {
      if (userRoles.includes(role)) {
        return true;
      }
    }

    return false;
  }, [authContext]);
};
