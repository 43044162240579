import { useEffect, useState } from 'react';
import { useAuthContext } from 'contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

type DataType<T> = {
    data: T | null,
    isLoading: boolean,
    error: any,
    refetch: () => void,
    refetchString: string
};

/**
 * @deprecated use useAuthQueryNew hook instead
 */
export default function useAuthQuery<T>(
  queryName: string,
  queryFn: (init: RequestInit) => Promise<T>,
  options: {
        enabled?: boolean;
        onSuccess?: (data: T) => void;
    } = {}
): DataType<T> {

  const navigate = useNavigate();

  const { getToken } = useAuthContext();
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [refetchString, setRefetchString] = useState<string>(queryName);

  const refetch = () => {
    setRefetchString(`${Math.random() * 1000}/${Date.now()}`);
  };


  useEffect(() => {
    let isCancelled = false;

    async function fetchData() {
      try {
        setIsLoading(true);

        const token = await getToken();
        const result = await queryFn({
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!isCancelled) {
          setData(result);
          if (options.onSuccess) {
            options.onSuccess(result);
          }
        }
      } catch (error: any) {
        setError(error);

        if (error?.statusCode && error?.statusCode === 403) {
          navigate('/403');
        }
      } finally {
        setIsLoading(false);
      }
    }

    if (options.enabled === undefined || options.enabled) {
      fetchData();
    }

    return () => {
      isCancelled = true;
    };
  }, [queryName, refetchString]);

  return { data, isLoading, error, refetch, refetchString };
}
