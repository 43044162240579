import { useCallback } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { PERMISSIONS_LIST, ROLES_LIST, ROLES_PERMISSIONS } from 'components/Auth/authConfig';

export const useCheckUserPermissionsByUserRole = () => {
  const authContext = useAuthContext();

  return useCallback((roleToCheck: ROLES_LIST, permissionsToCheck: PERMISSIONS_LIST[]): boolean => {
    if (!authContext || !authContext.user || !authContext.contextUser) {
      return false;
    }

    const userRoles: ROLES_LIST[] = authContext?.contextUser?.roles ?? [];
    if (userRoles?.some(role => role === ROLES_LIST.ROLE_SUPERUSER)) {
      return true;
    }

    const userPermissions: PERMISSIONS_LIST[] = authContext?.contextUser?.permissions ?? [];
    if (!userPermissions || !Array.isArray(userPermissions) || !userPermissions.length || userPermissions.length === 0) {
      return false;
    }

    if (!permissionsToCheck.length) {
      return false;
    }

    if (ROLES_PERMISSIONS[roleToCheck]) {
      for (const permission of permissionsToCheck) {
        if (ROLES_PERMISSIONS[roleToCheck]?.includes(permission)) {
          return true;
        }
      }
    }

    return false;
  }, [authContext]);
};
