import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {whoTheme} from './theme/theme';
import AuthProvider from './components/Auth/AzureAuthProvider';
import enGB from 'date-fns/locale/en-GB';
import { AppModalsContextProvider } from './contexts/providers/AppModalsContextProvider';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <AppModalsContextProvider>
      <ThemeProvider theme={whoTheme}>
        <AuthProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <App />
          </LocalizationProvider>
        </AuthProvider>
      </ThemeProvider>
    </AppModalsContextProvider>
  </BrowserRouter>
);

reportWebVitals();
