import React from 'react';
import { useQueryClient } from 'react-query';

import { QueryResource } from 'models/resources';

export const useInvalidateResource = () => {
  const client = useQueryClient();

  return React.useCallback((resource: QueryResource, ...args: unknown[]) => {
    client.invalidateQueries([resource, ...args]);
  }, []);
};
