import { create } from 'zustand';

import { AuthUser } from 'models/auth';
import { initialState } from './constants';

export interface IUserState {
  user: AuthUser;
  setUser: (user: AuthUser) => void;
  resetUser: () => void;
}

export const useUserStore = create<IUserState>((set) => ({
  user: initialState,
  setUser: (user) => set(() => ({ user })),
  resetUser: () => set(() => ({ user: initialState })),
}));
