import { createContext, useContext } from 'react';
import { AuthUser } from 'models/auth';

export interface AuthContextType {
    isAuthenticated: boolean | undefined;
    isInitialized: boolean | undefined;
    user: any;
    contextUser?: AuthUser,
    isPublicRoute?: boolean,
    login: (user: string, callback: VoidFunction) => void;
    logout: (callback: VoidFunction) => void;
    getToken: () => Promise<string>
}

const AuthContext = createContext<AuthContextType>(null!);

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = AuthContext.Provider;
