import { alpha } from "@mui/material";

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#CCCFCC',
  400: '#B3B5B6',
  500: '#979899',
  600: '#7e7f80',
  700: '#454F5B',
  800: '#313233',
  900: '#0c0d0e',
};

const PRIMARY = {
  main: '#00205C',
  contrastText: '#fff',
  light:'#072ac8'
  // light: '#677ba3',
};

const GREEN = {
  main: '#aacf7f',
  light: '#f6fcec',
  dark: '#80BC00',
  contrastText: '#fff'
};

const BLUE = {
  main: '#009ADE',
  light: '#DDEFF9',
  contrastText: '#fff'
};

const PURPLE = {
  main: '#5B2C86',
  light: '#E5DFED',
  contrastText: '#fff'
};

const YELLOW = {
  main: '#F4A81D',
  light: '#FEF3E3',
  contrastText: '#fff'
};

const GRAPH_COLORS = {
  1 : PRIMARY.main,
  2 : BLUE.main,
  3 : BLUE.light,
  4 : GREEN.dark,
  5 : YELLOW.main,
  6 : PURPLE.main,
  7 : GREEN.main
};

const GRAPH_COLORS_GRADIENT = {
  1 : alpha(BLUE.main, 0.2),
  2 : alpha(BLUE.main, 0.4),
  3 : alpha(BLUE.main, 0.7),
  4 : BLUE.main,
  5 : alpha(PRIMARY.main, 0.6),
  6 : alpha(PRIMARY.main, 0.8),
  7 : PRIMARY.main
};

const GRAPH_COLORS_GRADIENT_ALT = {
  1 : '#DDEFF9',
  2 : '#7DCAEC',
  3 : '#30B1E4',
  4 : '#009ADE',
  5 : '#0086C8',
  6 : '#005D9D',
  7 : '#00205C'
};

const GRAPH_COLORS_ALT = {
  1 : PRIMARY.main,
  2 : '#0077B6',
  3 : '#009ADE',
  4 : '#24B2E1',
  5 : '#1AA1B2',
  6 : '#4DAE59',
  7 : '#80BC00'
};

const palette =  { 
  primary : PRIMARY,
  secondary : BLUE,
  success: GREEN,
  green: GREEN,
  purple: PURPLE,
  info:YELLOW,
  grey: GREY,
  history: {main: GREY[200], light: GREY[100]},
  graph: GRAPH_COLORS, 
  graph_gradient: GRAPH_COLORS_GRADIENT_ALT,
  graph_alt: GRAPH_COLORS_ALT,
  background: {
    paper: GREY[0],
    default: GREY[100],
    neutral: GREY[200]
  },
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  visit_calendar: 
    {
      online: GREY[600],
      first_mentor: BLUE.main,
      mentor: PURPLE.main,
      pre_verification: YELLOW.main,
      verification: GREEN.dark,
      other:PRIMARY.light,
  
    }
};


export default palette;
