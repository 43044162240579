import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import styles from './LogoutModal.module.scss';

type Props = {
  onLogout(): void;
}

const LogoutModal: React.FC<Props> = (props) => (
  <Dialog open>
    <DialogTitle>Unable to login</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Something went wrong, please log out of your account
      </DialogContentText>
    </DialogContent>
    <DialogActions className={styles.actions}>
      <Button onClick={props.onLogout}>Logout</Button>
    </DialogActions>
  </Dialog>
);

export default React.memo(LogoutModal);