import { fetchJson } from './helpers/request';
import { CreateURLSearchParams } from './helpers/createURLSearchParams';

const AUTH_BASE_URL = process.env.REACT_APP_API_AUTH_BASE_URL;

export interface UserInfo {
    id: number,
    uniqueName: string,
    country: string,
    region: string,
    personCode: string,
    organizationCode: string,
    organizationName: string,
    teamCode: string,
    teamName: string,
    isActualTfp: boolean,
    isActualNfp: boolean,
    isActualMentor: boolean,
    isGov: boolean,
    permissions: string[],
    roles: string[],
    name: string,
    organizations: IAuthOrganization [],
}

export interface IAuthOrganization {
  organizationCode: string,
  organizationName: string,
  isGov: boolean,
  isActualTfp: boolean,
  teams: IAuthTeam []
}

export interface IAuthTeam {
  teamCode: string,
  teamName: string,
  teamLead: boolean
}

export function getAuthData(init?: RequestInit): Promise<any> {
  return fetchJson(`${AUTH_BASE_URL}/authentication`, {
    method: 'POST',
    headers: init?.headers
  });
}

export function getAuthUsers(params: {
    page: number,
    limit: number,
    sort?: 'asc' | 'desc' | null,
    order: string,
    isActualNfp?: boolean | string,
    isActualTfp?: boolean | string,
    isActualMentor?: boolean | string,
    isGov?: boolean | string,
    isNgo?: boolean | string,
    region: string
}, init?: RequestInit): Promise<{
    items: UserInfo[],
    totalPages: number
}> {
  return fetchJson(`${AUTH_BASE_URL}/users${CreateURLSearchParams({ ...params, sort: params.sort as string })}`, {
    method: 'GET',
    headers: init?.headers
  });
}

export function updateUsers(init?: RequestInit): Promise<UserInfo[]> {
  return fetchJson(`${AUTH_BASE_URL}/users/update`, {
    method: 'POST',
    headers: init?.headers
  });
}

export function getAuthUserById(params: {
    userId: string | number
}, init?: RequestInit): Promise<UserInfo> {
  return fetchJson(`${AUTH_BASE_URL}/users/${params.userId}`, {
    method: 'GET',
    headers: init?.headers
  });
}

export function updateAuthUserById(params: {
    userId: string | number,
    region: string
}, init?: RequestInit): Promise<UserInfo> {
  return fetchJson(`${AUTH_BASE_URL}/users/${params.userId}`, {
    method: 'PATCH',
    headers: init?.headers,
    body: JSON.stringify({
      region: params.region
    })
  });
}