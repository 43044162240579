import React from 'react';
import { NavigateFunction, Location, useLocation, useNavigate } from 'react-router-dom';

import { Alert, Backdrop, Box, Button, Modal, Typography } from '@mui/material';

import palette from 'theme/palette';

import styles from './ErrorBoundary.module.css';

type Props = {
  children: React.ReactNode;
  location: Location;
  navigate: NavigateFunction;
};

type State = {
  hasError: boolean;
};

class ErrorBoundaryComponent extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // send request here
    console.log('error', error);
    console.log('errorInfo', errorInfo);
  }

  goToMainPage = (): void => {
    this.props.navigate('/');
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return (
        <Modal
          open
          onClose={this.goToMainPage}
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: palette.grey[200]
              },
            },
          }}
        >
          <Box className={styles.modal}>
            <Typography variant='h6'>Error</Typography>
            <Typography variant='body2' sx={{ mb: 2 }}>
              We are already working on the problem
            </Typography>
            <Alert severity='error' className={styles.alert}>
              Something went wrong
            </Alert>
            <Button
              variant='outlined'
              onClick={this.goToMainPage}
              className={styles.btn}
            >
              Main page
            </Button>
          </Box>
        </Modal>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withNavigation(ErrorBoundaryComponent);

export function withNavigation(WrappedComponent: React.ComponentType<Props>) {
  const Component = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    return <WrappedComponent {...props} navigate={navigate} location={location} />;
  };

  Component.displayName = 'withNavigationComponent';
  return Component;
}
