import React from 'react';
import { clearLockedBodyScroll, lockBodyScroll } from 'tools/disableScroll';

export const useLockBodyScroll = (state: boolean): void => {
  React.useEffect(() => {
    if (state) {
      lockBodyScroll();
    } else {
      clearLockedBodyScroll();
    }
  }, [state]);
};