import { useCallback } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { PERMISSIONS_LIST, ROLES_LIST } from 'components/Auth/authConfig';

export const useCheckUserPermissions = () => {
  const authContext = useAuthContext();

  return useCallback((permissionsToCheck: PERMISSIONS_LIST[]): boolean => {
    if (!authContext || !authContext.user || !authContext.contextUser) {
      return false;
    }

    const userRoles: ROLES_LIST[] = authContext?.contextUser?.roles ?? [];
    if (userRoles?.some(role => role === ROLES_LIST.ROLE_SUPERUSER)) {
      return true;
    }

    const userPermissions: PERMISSIONS_LIST[] = authContext?.contextUser?.permissions ?? [];
    if (!userPermissions || !Array.isArray(userPermissions) || !userPermissions.length || userPermissions.length === 0) {
      return false;
    }

    if (!permissionsToCheck.length) {
      return false;
    }

    for (const permission of permissionsToCheck) {
      if (userPermissions.includes(permission)) {
        return true;
      }
    }

    return false;
  }, [authContext]);
};
