import { useAuthQueryNew } from './useAuthQueryNew';
import { getCountries } from 'api/person-api';

const CountryReferenceQueryKey = ['countryReference'];

export function useCountryReference() {
  return useAuthQueryNew(CountryReferenceQueryKey, getCountries, {
    staleTime: Infinity
  });
}

