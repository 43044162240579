export const CreateURLSearchParams = (params?: Record<string, string | number | boolean | undefined>): string => {

  if (!params) return '';

  const searchParams = new URLSearchParams();
  Object.keys(params).filter(key => params[key] !== undefined && params[key] !== '')
    .forEach(key => searchParams.set(key, String(params[key])));

  return `?${searchParams.toString()}`;
};
