// ----------------------------------------------------------------------

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


function pxToRem(value: number) {
  return `${value / 16}rem`;
}

const FONT_PRIMARY =
[
  'Inter',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

/*
Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
*/

//`Inter, 'Public Sans', sans-serif`;
const base_font_size = 13;
const font_weight_medium = 500;
const font_weight_regular = 400;
const font_weight_bold = 600;

const typography = {

  fontFamily: FONT_PRIMARY,
  fontWeightRegular: font_weight_regular,
  fontWeightMedium: font_weight_medium,
  fontWeightBold: font_weight_bold,


  button: {
    textTransform: 'none' as const,
  },


  h1: {
  
    fontWeight: 700,
    letterSpacing: '.05rem',
    lineHeight: 1.0,
    fontSize: pxToRem(base_font_size + 48),
  },

  h2: {
    fontWeight: 700,
    letterSpacing: '.05rem',
    lineHeight: 1.0,
    fontSize: pxToRem(base_font_size + 36),
  },

  h3: {
    fontWeight: font_weight_bold,
    lineHeight: 1.25,
    fontSize: '1.5rem',
  },

  h4: {
    fontWeight: font_weight_bold,
    lineHeight: 1.25,
    letterSpacing: '.03rem',
    fontSize: '1.2rem',
  },

  h5: {
    fontWeight: font_weight_bold,
    lineHeight: 1.25,
    letterSpacing: '.03rem',
    fontSize: pxToRem(base_font_size + 3),
  },

  h6: {
    fontWeight: font_weight_bold,
    lineHeight: 1.5,
    fontSize: '1.0rem',
  },

  subtitle1: {
    fontWeight: font_weight_medium,
    lineHeight: 1.25,
    fontSize: pxToRem(base_font_size + 3)
  },

  subtitle2: {
    fontWeight: font_weight_bold,
    lineHeight: 1.5,
    fontSize: pxToRem(base_font_size)
  },

  body1: {
    fontWeight: font_weight_regular,
    lineHeight: 1.43,
    fontSize: pxToRem(base_font_size + 2)
  },

  body2: {
    fontWeight: font_weight_regular,
    lineHeight: 1.5,
    fontSize: pxToRem(base_font_size)
  },


};

export default typography;
